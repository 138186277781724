import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarSectionMessage = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>

*/
{
  "id": "AUqDwDAl",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"sidebar-section-message-wrapper sidebar-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"sidebar-section-message\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/section-message.js",
  "isStrictMode": true
}), templateOnly());
export default SidebarSectionMessage;