var _BadgeButton;
import Component from "@glimmer/component";
import iconOrImage from "discourse/helpers/icon-or-image";
import domFromString from "discourse-common/lib/dom-from-string";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class BadgeButton extends Component {
  get title() {
    const description1 = this.args.badge?.description;
    if (description1) {
      return domFromString(`<div>${description1}</div>`)[0].innerText;
    }
  }
}
_BadgeButton = BadgeButton;
setComponentTemplate(createTemplateFactory(
/*
  
    <span
      title={{this.title}}
      data-badge-name={{@badge.name}}
      class="user-badge
        {{@badge.badgeTypeClassName}}
        {{unless @badge.enabled 'disabled'}}"
      ...attributes
    >
      {{iconOrImage @badge}}
      <span class="badge-display-name">{{@badge.name}}</span>
      {{yield}}
    </span>
  
*/
{
  "id": "r5UNGzU4",
  "block": "[[[1,\"\\n    \"],[11,1],[16,\"title\",[30,0,[\"title\"]]],[16,\"data-badge-name\",[30,1,[\"name\"]]],[16,0,[29,[\"user-badge\\n        \",[30,1,[\"badgeTypeClassName\"]],\"\\n        \",[52,[51,[30,1,[\"enabled\"]]],\"disabled\"]]]],[17,2],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1]],null]],[1,\"\\n      \"],[10,1],[14,0,\"badge-display-name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@badge\",\"&attrs\",\"&default\"],false,[\"unless\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/badge-button.js",
  "scope": () => [iconOrImage],
  "isStrictMode": true
}), _BadgeButton);