import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _DBreadcrumbsItem;
import Component from "@glimmer/component";
import { inject as service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let DBreadcrumbsItem = (_class = (_DBreadcrumbsItem = class DBreadcrumbsItem extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "breadcrumbsService", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#each this.breadcrumbsService.containers as |container|}}
      {{#in-element container.element insertBefore=null}}
        <li class={{container.itemClass}} ...attributes>
          {{yield container.linkClass}}
        </li>
      {{/in-element}}
    {{/each}}
  
*/
{
  "id": "GoG0xqvd",
  "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"breadcrumbsService\",\"containers\"]]],null]],null],null,[[[40,[[[1,\"        \"],[11,\"li\"],[16,0,[30,1,[\"itemClass\"]]],[17,2],[12],[1,\"\\n          \"],[18,3,[[30,1,[\"linkClass\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[31,3],[[30,1,[\"element\"]]],null],null]],[1]],null],[1,\"  \"]],[\"container\",\"&attrs\",\"&default\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-item.js",
  "isStrictMode": true
}), _DBreadcrumbsItem), _DBreadcrumbsItem), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "breadcrumbsService", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DBreadcrumbsItem as default };