import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _SidebarUserTagsSection;
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { array, hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarEditNavigationMenuTagsModal from "discourse/components/sidebar/edit-navigation-menu/tags-modal";
import { hasDefaultSidebarTags } from "discourse/lib/sidebar/helpers";
import PMTagSectionLink from "discourse/lib/sidebar/user/tags-section/pm-tag-section-link";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import i18n from "discourse-common/helpers/i18n";
import AllTagsSectionLink from "../common/all-tags-section-link";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
let SidebarUserTagsSection = (_class = (_SidebarUserTagsSection = class SidebarUserTagsSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "modal", _descriptor2, this);
    _initializerDefineProperty(this, "pmTopicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "router", _descriptor4, this);
    _initializerDefineProperty(this, "site", _descriptor5, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor6, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor7, this);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this.sectionLinks.forEach(sectionLink1 => {
        if (sectionLink1.refreshCounts) {
          sectionLink1.refreshCounts();
        }
      });
    });
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
  }
  get sectionLinks() {
    const links1 = [];
    let tags1;
    if (this.currentUser.sidebarTags.length > 0) {
      tags1 = this.currentUser.sidebarTags;
    } else {
      tags1 = this.site.navigation_menu_site_top_tags || [];
    }
    for (const tag1 of tags1) {
      if (tag1.pm_only) {
        links1.push(new PMTagSectionLink({
          tag: tag1,
          currentUser: this.currentUser
        }));
      } else {
        links1.push(new TagSectionLink({
          tag: tag1,
          topicTrackingState: this.topicTrackingState,
          currentUser: this.currentUser
        }));
      }
    }
    return links1;
  }
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarTags;
  }
  get hasDefaultSidebarTags() {
    return hasDefaultSidebarTags(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuTagsModal);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <Section
      @sectionName="tags"
      @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
      @headerActions={{array
        (hash
          action=this.showModal
          title=(i18n "sidebar.sections.tags.header_action_title")
        )
      }}
      @headerActionsIcon="pencil-alt"
      @collapsable={{@collapsable}}
    >
      {{#each this.sectionLinks as |sectionLink|}}
        <SectionLink
          @route={{sectionLink.route}}
          @title={{sectionLink.title}}
          @content={{sectionLink.text}}
          @currentWhen={{sectionLink.currentWhen}}
          @prefixType={{sectionLink.prefixType}}
          @prefixValue={{sectionLink.prefixValue}}
          @prefixColor={{sectionLink.prefixColor}}
          @badgeText={{sectionLink.badgeText}}
          @models={{sectionLink.models}}
          @suffixCSSClass={{sectionLink.suffixCSSClass}}
          @suffixValue={{sectionLink.suffixValue}}
          @suffixType={{sectionLink.suffixType}}
          data-tag-name={{sectionLink.tagName}}
        />
      {{/each}}

      <AllTagsSectionLink />

      {{#if this.shouldDisplayDefaultConfig}}
        <SectionLink
          @linkName="configure-default-navigation-menu-tags"
          @content={{i18n "sidebar.sections.tags.configure_defaults"}}
          @prefixType="icon"
          @prefixValue="wrench"
          @route="adminSiteSettingsCategory"
          @model="sidebar"
          @query={{hash filter="default_navigation_menu_tags"}}
        />
      {{/if}}
    </Section>
  
*/
{
  "id": "B9hA1kcS",
  "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@headerActions\",\"@headerActionsIcon\",\"@collapsable\"],[\"tags\",[28,[32,1],[\"sidebar.sections.tags.header_link_text\"],null],[28,[32,2],[[28,[32,3],null,[[\"action\",\"title\"],[[30,0,[\"showModal\"]],[28,[32,1],[\"sidebar.sections.tags.header_action_title\"],null]]]]],null],\"pencil-alt\",[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"        \"],[8,[32,4],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@title\",\"@content\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@badgeText\",\"@models\",\"@suffixCSSClass\",\"@suffixValue\",\"@suffixType\"],[[30,2,[\"route\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"badgeText\"]],[30,2,[\"models\"]],[30,2,[\"suffixCSSClass\"]],[30,2,[\"suffixValue\"]],[30,2,[\"suffixType\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"],[8,[32,5],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplayDefaultConfig\"]],[[[1,\"        \"],[8,[32,4],null,[[\"@linkName\",\"@content\",\"@prefixType\",\"@prefixValue\",\"@route\",\"@model\",\"@query\"],[\"configure-default-navigation-menu-tags\",[28,[32,1],[\"sidebar.sections.tags.configure_defaults\"],null],\"icon\",\"wrench\",\"adminSiteSettingsCategory\",\"sidebar\",[28,[32,3],null,[[\"filter\"],[\"default_navigation_menu_tags\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/user/tags-section.js",
  "scope": () => [Section, i18n, array, hash, SectionLink, AllTagsSectionLink],
  "isStrictMode": true
}), _SidebarUserTagsSection), _SidebarUserTagsSection), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pmTopicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "sectionLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype)), _class);
export { SidebarUserTagsSection as default };