import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Discovery::Layout @model={{this.model}}>
  <:navigation>
    <Discovery::Navigation
      @category={{this.model.parentCategory}}
      @showCategoryAdmin={{this.model.can_create_category}}
      @canCreateTopic={{this.model.can_create_topic}}
      @createTopic={{this.createTopic}}
      @filterType="categories"
    />
  </:navigation>
  <:list>

    {{body-class "categories-list"}}

    <div class="contents">
      {{#if (and this.topicTrackingState.hasIncoming this.isCategoriesRoute)}}
        <div
          class={{concat-class "show-more" (if this.hasTopics "has-topics")}}
        >
          <div
            role="button"
            class="alert alert-info clickable"
            {{on "click" this.showInserted}}
          >
            <CountI18n
              @key="topic_count_"
              @suffix={{this.topicTrackingState.filter}}
              @count={{this.topicTrackingState.incomingCount}}
            />
          </div>
        </div>
      {{/if}}

      <Discovery::CategoriesDisplay
        @categories={{this.model.categories}}
        @topics={{this.model.topics}}
        @parentCategory={{this.model.parentCategory}}
        @loadMore={{this.model.loadMore}}
        @loadingMore={{this.model.isLoading}}
      />
    </div>

    <PluginOutlet
      @name="below-discovery-categories"
      @connectorTagName="div"
      @outletArgs={{hash
        categories=this.model.categories
        topics=this.model.topics
      }}
    />
  </:list>
</Discovery::Layout>
*/
{
  "id": "TxW0fZcc",
  "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"navigation\",\"list\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@category\",\"@showCategoryAdmin\",\"@canCreateTopic\",\"@createTopic\",\"@filterType\"],[[30,0,[\"model\",\"parentCategory\"]],[30,0,[\"model\",\"can_create_category\"]],[30,0,[\"model\",\"can_create_topic\"]],[30,0,[\"createTopic\"]],\"categories\"]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n\\n    \"],[1,[28,[35,2],[\"categories-list\"],null]],[1,\"\\n\\n    \"],[10,0],[14,0,\"contents\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"topicTrackingState\",\"hasIncoming\"]],[30,0,[\"isCategoriesRoute\"]]],null],[[[1,\"        \"],[10,0],[15,0,[28,[37,5],[\"show-more\",[52,[30,0,[\"hasTopics\"]],\"has-topics\"]],null]],[12],[1,\"\\n          \"],[11,0],[24,\"role\",\"button\"],[24,0,\"alert alert-info clickable\"],[4,[38,6],[\"click\",[30,0,[\"showInserted\"]]],null],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@key\",\"@suffix\",\"@count\"],[\"topic_count_\",[30,0,[\"topicTrackingState\",\"filter\"]],[30,0,[\"topicTrackingState\",\"incomingCount\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[39,8],null,[[\"@categories\",\"@topics\",\"@parentCategory\",\"@loadMore\",\"@loadingMore\"],[[30,0,[\"model\",\"categories\"]],[30,0,[\"model\",\"topics\"]],[30,0,[\"model\",\"parentCategory\"]],[30,0,[\"model\",\"loadMore\"]],[30,0,[\"model\",\"isLoading\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,9],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"below-discovery-categories\",\"div\",[28,[37,10],null,[[\"categories\",\"topics\"],[[30,0,[\"model\",\"categories\"]],[30,0,[\"model\",\"topics\"]]]]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"discovery/layout\",\"discovery/navigation\",\"body-class\",\"if\",\"and\",\"concat-class\",\"on\",\"count-i18n\",\"discovery/categories-display\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/templates/discovery/categories.hbs",
  "isStrictMode": false
});