import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _TopicTitle;
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import PluginOutlet from "discourse/components/plugin-outlet";
import { isiPad } from "discourse/lib/utilities";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export let topicTitleDecorators = [];
export function addTopicTitleDecorator(decorator1) {
  topicTitleDecorators.push(decorator1);
}
export function resetTopicTitleDecorators() {
  topicTitleDecorators.length = 0;
}
let TopicTitle = (_class = (_TopicTitle = class TopicTitle extends Component {
  applyDecorators(element1) {
    const fancyTitle1 = element1.querySelector(".fancy-title");
    if (fancyTitle1) {
      topicTitleDecorators.forEach(cb1 => cb1(this.args.model, fancyTitle1, "topic-title"));
    }
  }
  keyDown(e1) {
    if (document.body.classList.contains("modal-open")) {
      return;
    }
    if (e1.key === "Escape") {
      e1.preventDefault();
      this.args.cancelled();
    } else if (e1.key === "Enter" && (e1.ctrlKey || e1.metaKey || isiPad() && e1.altKey)) {
      // Ctrl+Enter or Cmd+Enter
      // iPad physical keyboard does not offer Command or Ctrl detection
      // so use Alt+Enter
      e1.preventDefault();
      this.args.save(undefined, e1);
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{! template-lint-disable no-invalid-interactive }}
    <div
      {{didInsert this.applyDecorators}}
      {{on "keydown" this.keyDown}}
      id="topic-title"
      class="container"
    >
      <div class="title-wrapper">
        {{yield}}
      </div>

      <PluginOutlet
        @name="topic-title"
        @connectorTagName="div"
        @outletArgs={{hash model=@model}}
      />
    </div>
  
*/
{
  "id": "1hZ5nXOD",
  "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,1,\"topic-title\"],[24,0,\"container\"],[4,[32,0],[[30,0,[\"applyDecorators\"]]],null],[4,[32,1],[\"keydown\",[30,0,[\"keyDown\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"topic-title\",\"div\",[28,[32,3],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-title.js",
  "scope": () => [didInsert, on, PluginOutlet, hash],
  "isStrictMode": true
}), _TopicTitle), _TopicTitle), (_applyDecoratedDescriptor(_class.prototype, "applyDecorators", [action], Object.getOwnPropertyDescriptor(_class.prototype, "applyDecorators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keyDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "keyDown"), _class.prototype)), _class);
export { TopicTitle as default };