import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _AboutPageUsers;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { renderAvatar } from "discourse/helpers/user-avatar";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { userPath } from "discourse/lib/url";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let AboutPageUsers = (_class = (_AboutPageUsers = class AboutPageUsers extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
  get usersTemplates() {
    return (this.args.users || []).map(user1 => ({
      name: user1.name,
      username: user1.username,
      userPath: userPath(user1.username),
      avatar: renderAvatar(user1, {
        imageSize: "large",
        siteSettings: this.siteSettings
      }),
      title: user1.title || "",
      prioritizeName: prioritizeNameInUx(user1.name)
    }));
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#each this.usersTemplates as |template|}}
      <div data-username={{template.username}} class="user-info small">
        <div class="user-image">
          <div class="user-image-inner">
            <a
              href={{template.userPath}}
              data-user-card={{template.username}}
              aria-hidden="true"
            >
              {{htmlSafe template.avatar}}
            </a>
          </div>
        </div>
        <div class="user-detail">
          <div class="name-line">
            <a
              href={{template.userPath}}
              data-user-card={{template.username}}
              aria-label={{i18n
                "user.profile_possessive"
                username=template.username
              }}
            >
              <span class="username">
                {{#if template.prioritizeName}}
                  {{template.name}}
                {{else}}
                  {{template.username}}
                {{/if}}
              </span>
              <span class="name">
                {{#if template.prioritizeName}}
                  {{template.username}}
                {{else}}
                  {{template.name}}
                {{/if}}
              </span>
            </a>
          </div>
          <div class="title">{{template.title}}</div>
        </div>
      </div>
    {{/each}}
  
*/
{
  "id": "oT0mKi3Z",
  "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"usersTemplates\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-username\",[30,1,[\"username\"]]],[14,0,\"user-info small\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"user-image\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"user-image-inner\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n              \"],[1,[28,[32,0],[[30,1,[\"avatar\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"user-detail\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"name-line\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[15,\"aria-label\",[28,[32,1],[\"user.profile_possessive\"],[[\"username\"],[[30,1,[\"username\"]]]]]],[12],[1,\"\\n              \"],[10,1],[14,0,\"username\"],[12],[1,\"\\n\"],[41,[30,1,[\"prioritizeName\"]],[[[1,\"                  \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[30,1,[\"username\"]]],[1,\"\\n\"]],[]]],[1,\"              \"],[13],[1,\"\\n              \"],[10,1],[14,0,\"name\"],[12],[1,\"\\n\"],[41,[30,1,[\"prioritizeName\"]],[[[1,\"                  \"],[1,[30,1,[\"username\"]]],[1,\"\\n\"]],[]],[[[1,\"                  \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[\"template\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/about-page-users.js",
  "scope": () => [htmlSafe, i18n],
  "isStrictMode": true
}), _AboutPageUsers), _AboutPageUsers), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { AboutPageUsers as default };