import concatClass from "discourse/helpers/concat-class";
import dBreadcrumbsContainerModifier from "discourse/modifiers/d-breadcrumbs-container-modifier";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const DBreadcrumbsContainer = setComponentTemplate(createTemplateFactory(
/*
  
  <ul
    class="d-breadcrumbs"
    {{dBreadcrumbsContainerModifier
      itemClass=(concatClass "d-breadcrumbs__item" @additionalItemClasses)
      linkClass=(concatClass "d-breadcrumbs__link" @additionalLinkClasses)
    }}
    ...attributes
  >
    {{yield}}
  </ul>

*/
{
  "id": "IsyZvf6n",
  "block": "[[[1,\"\\n  \"],[11,\"ul\"],[24,0,\"d-breadcrumbs\"],[17,1],[4,[32,0],null,[[\"itemClass\",\"linkClass\"],[[28,[32,1],[\"d-breadcrumbs__item\",[30,2]],null],[28,[32,1],[\"d-breadcrumbs__link\",[30,3]],null]]]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@additionalItemClasses\",\"@additionalLinkClasses\",\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-container.js",
  "scope": () => [dBreadcrumbsContainerModifier, concatClass],
  "isStrictMode": true
}), templateOnly());
export default DBreadcrumbsContainer;