import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _SidebarCustomSection;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import CustomSection from "./custom-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarCustomSection = (_class = (_SidebarCustomSection = class SidebarCustomSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "messageBus", _descriptor3, this);
    _initializerDefineProperty(this, "appEvents", _descriptor4, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor5, this);
    _initializerDefineProperty(this, "site", _descriptor6, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor7, this);
    _defineProperty(this, "anonymous", false);
  }
  get sections() {
    if (this.anonymous) {
      return this.site.anonymous_sidebar_sections;
    } else {
      return this.currentUser.sidebarSections;
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="sidebar-custom-sections">
      {{#each this.sections as |section|}}
        <CustomSection @sectionData={{section}} @collapsable={{@collapsable}} />
      {{/each}}
    </div>
  
*/
{
  "id": "RTfM/mf4",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-custom-sections\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@sectionData\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"section\",\"@collapsable\"],false,[\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/common/custom-sections.js",
  "scope": () => [CustomSection],
  "isStrictMode": true
}), _SidebarCustomSection), _SidebarCustomSection), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarCustomSection as default };