import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _SidebarAnonymousSections;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import TagsSection from "./tags-section";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarAnonymousSections = (_class = (_SidebarAnonymousSections = class SidebarAnonymousSections extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="sidebar-sections sidebar-sections-anonymous">
      <CustomSections @collapsable={{@collapsableSections}} />
      <CategoriesSection @collapsable={{@collapsableSections}} />

      {{#if this.siteSettings.tagging_enabled}}
        <TagsSection @collapsable={{@collapsableSections}} />
      {{/if}}
    </div>
  
*/
{
  "id": "zogYd21w",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"sidebar-sections sidebar-sections-anonymous\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@collapsableSections\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/anonymous/sections.js",
  "scope": () => [CustomSections, CategoriesSection, TagsSection],
  "isStrictMode": true
}), _SidebarAnonymousSections), _SidebarAnonymousSections), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarAnonymousSections as default };