import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _DiscardDraftModal;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let DiscardDraftModal = (_class = (_DiscardDraftModal = class DiscardDraftModal extends Component {
  async discardDraft() {
    await this.args.model.onDestroyDraft();
    this.args.closeModal();
  }
  async saveDraftAndClose() {
    await this.args.model.onSaveDraft();
    this.args.closeModal();
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DModal
      @closeModal={{@closeModal}}
      class="discard-draft-modal"
      @dismissable={{false}}
    >
      <:body>
        <div class="instructions">
          {{i18n "post.cancel_composer.confirm"}}
        </div>
      </:body>

      <:footer>
        <DButton
          @icon="far-trash-alt"
          @label="post.cancel_composer.discard"
          @action={{this.discardDraft}}
          class="btn-danger discard-draft"
        />
        {{#if @model.showSaveDraftButton}}
          <DButton
            @label="post.cancel_composer.save_draft"
            @action={{this.saveDraftAndClose}}
            class="save-draft"
          />
        {{/if}}
        <DButton
          @label="post.cancel_composer.keep_editing"
          @action={{@closeModal}}
          class="keep-editing"
        />
      </:footer>
    </DModal>
  
*/
{
  "id": "JMP8Tn9P",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"discard-draft-modal\"]],[[\"@closeModal\",\"@dismissable\"],[[30,1],false]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"post.cancel_composer.confirm\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-danger discard-draft\"]],[[\"@icon\",\"@label\",\"@action\"],[\"far-trash-alt\",\"post.cancel_composer.discard\",[30,0,[\"discardDraft\"]]]],null],[1,\"\\n\"],[41,[30,2,[\"showSaveDraftButton\"]],[[[1,\"          \"],[8,[32,2],[[24,0,\"save-draft\"]],[[\"@label\",\"@action\"],[\"post.cancel_composer.save_draft\",[30,0,[\"saveDraftAndClose\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[32,2],[[24,0,\"keep-editing\"]],[[\"@label\",\"@action\"],[\"post.cancel_composer.keep_editing\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal/discard-draft.js",
  "scope": () => [DModal, i18n, DButton],
  "isStrictMode": true
}), _DiscardDraftModal), _DiscardDraftModal), (_applyDecoratedDescriptor(_class.prototype, "discardDraft", [action], Object.getOwnPropertyDescriptor(_class.prototype, "discardDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveDraftAndClose", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveDraftAndClose"), _class.prototype)), _class);
export { DiscardDraftModal as default };