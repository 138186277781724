import i18n from "discourse-common/helpers/i18n";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarCommonAllTagsSectionLink = setComponentTemplate(createTemplateFactory(
/*
  
  <SectionLink
    @linkName="all-tags"
    @content={{i18n "sidebar.all_tags"}}
    @route="tags"
    @prefixType="icon"
    @prefixValue="list"
  />

*/
{
  "id": "ifEa62Ex",
  "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@linkName\",\"@content\",\"@route\",\"@prefixType\",\"@prefixValue\"],[\"all-tags\",[28,[32,1],[\"sidebar.all_tags\"],null],\"tags\",\"icon\",\"list\"]],null],[1,\"\\n\"]],[],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/common/all-tags-section-link.js",
  "scope": () => [SectionLink, i18n],
  "isStrictMode": true
}), templateOnly());
export default SidebarCommonAllTagsSectionLink;