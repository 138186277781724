import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _OfflineIndicator;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let OfflineIndicator = (_class = (_OfflineIndicator = class OfflineIndicator extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "networkConnectivity", _descriptor, this);
  }
  get showing() {
    return !this.networkConnectivity.connected;
  }
  refresh() {
    window.location.reload(true);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.showing}}
      <div class="offline-indicator">
        <span>{{i18n "offline_indicator.no_internet"}}</span>
        <DButton
          @label="offline_indicator.refresh_page"
          @display="link"
          @action={{this.refresh}}
        />
      </div>
    {{/if}}
  
*/
{
  "id": "rmsKiOI3",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"showing\"]],[[[1,\"      \"],[10,0],[14,0,\"offline-indicator\"],[12],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,0],[\"offline_indicator.no_internet\"],null]],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@label\",\"@display\",\"@action\"],[\"offline_indicator.refresh_page\",\"link\",[30,0,[\"refresh\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/offline-indicator.js",
  "scope": () => [i18n, DButton],
  "isStrictMode": true
}), _OfflineIndicator), _OfflineIndicator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "networkConnectivity", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "refresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class);
export { OfflineIndicator as default };