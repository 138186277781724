import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _Dropdown;
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { and } from "truth-helpers";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import i18n from "discourse-common/helpers/i18n";
import closeOnClickOutside from "../../modifiers/close-on-click-outside";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let Dropdown = (_class = (_Dropdown = class Dropdown extends Component {
  click(e1) {
    if (wantsNewWindow(e1)) {
      return;
    }
    e1.preventDefault();
    this.args.onClick(e1);
    // remove the focus of the header dropdown button after clicking
    e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <li
      class={{concatClass
        @className
        (if @active "active")
        "header-dropdown-toggle"
      }}
      {{(if
        (and @active @targetSelector)
        (modifier
          closeOnClickOutside @onClick (hash targetSelector=@targetSelector)
        )
      )}}
    >
      <DButton
        class="icon btn-flat"
        aria-expanded={{@active}}
        aria-haspopup="true"
        @translatedTitle={{i18n @title}}
        aria-label={{i18n @title}}
        id={{@iconId}}
        @icon={{@icon}}
        @translatedLabel={{@contents}}
        {{on "click" this.click}}
      />

    </li>
  
*/
{
  "id": "q1RHfF4y",
  "block": "[[[1,\"\\n    \"],[11,\"li\"],[16,0,[28,[32,0],[[30,1],[52,[30,2],\"active\"],\"header-dropdown-toggle\"],null]],[4,[52,[28,[32,1],[[30,2],[30,3]],null],[50,[32,2],2,[[30,4],[28,[32,3],null,[[\"targetSelector\"],[[30,3]]]]],null]],null,null],[12],[1,\"\\n      \"],[8,[32,4],[[24,0,\"icon btn-flat\"],[16,\"aria-expanded\",[30,2]],[24,\"aria-haspopup\",\"true\"],[16,\"aria-label\",[28,[32,5],[[30,5]],null]],[16,1,[30,6]],[4,[32,6],[\"click\",[30,0,[\"click\"]]],null]],[[\"@translatedTitle\",\"@icon\",\"@translatedLabel\"],[[28,[32,5],[[30,5]],null],[30,7],[30,8]]],null],[1,\"\\n\\n    \"],[13],[1,\"\\n  \"]],[\"@className\",\"@active\",\"@targetSelector\",\"@onClick\",\"@title\",\"@iconId\",\"@icon\",\"@contents\"],false,[\"if\",\"modifier\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/dropdown.js",
  "scope": () => [concatClass, and, closeOnClickOutside, hash, DButton, i18n, on],
  "isStrictMode": true
}), _Dropdown), _Dropdown), (_applyDecoratedDescriptor(_class.prototype, "click", [action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
export { Dropdown as default };