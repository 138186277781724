import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { queryParams, resetParams } from "discourse/controllers/discovery/list";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import PreloadStore from "discourse/lib/preload-store";
import { setTopicList } from "discourse/lib/topic-list-tracker";
import Category from "discourse/models/category";
import CategoryList from "discourse/models/category-list";
import TopicList from "discourse/models/topic-list";
import { filterQueryParams, findTopicList } from "discourse/routes/build-topic-route";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let AbstractCategoryRoute = (_dec = service("search"), disableImplicitInjections(_class = (_class2 = class AbstractCategoryRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "composer", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
    _initializerDefineProperty(this, "store", _descriptor4, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor5, this);
    _initializerDefineProperty(this, "searchService", _descriptor6, this);
    _initializerDefineProperty(this, "historyStore", _descriptor7, this);
    _defineProperty(this, "queryParams", queryParams);
    _defineProperty(this, "templateName", "discovery/list");
    _defineProperty(this, "controllerName", "discovery/list");
  }
  async model(params, transition) {
    const category = this.site.lazy_load_categories ? await Category.asyncFindBySlugPathWithID(params.category_slug_path_with_id) : Category.findBySlugPathWithID(params.category_slug_path_with_id);
    if (!category) {
      this.router.replaceWith("/404");
      return;
    }
    if (this.routeConfig?.no_subcategories === undefined && category.default_list_filter === "none" && this.routeConfig?.filter === "default" && params) {
      // TODO: avoid throwing away preload data by redirecting on the server
      PreloadStore.getAndRemove("topic_list");
      this.router.replaceWith("discovery.categoryNone", params.category_slug_path_with_id);
      return;
    }
    const subcategoryListPromise = this._createSubcategoryList(category);
    const topicListPromise = this._retrieveTopicList(category, transition, params);
    const noSubcategories = !!this.routeConfig?.no_subcategories;
    const filterType = this.filter(category).split("/")[0];
    return {
      category,
      modelParams: params,
      subcategoryList: await subcategoryListPromise,
      list: await topicListPromise,
      noSubcategories,
      filterType
    };
  }
  filter(category) {
    return this.routeConfig?.filter === "default" ? category.get("default_view") || "latest" : this.routeConfig?.filter;
  }
  async _createSubcategoryList(category) {
    if (category.isParent && category.show_subcategory_list) {
      return CategoryList.list(this.store, category);
    }
  }
  async _retrieveTopicList(category, transition, modelParams) {
    const findOpts = filterQueryParams(modelParams, this.routeConfig);
    const extras = {
      cached: this.historyStore.isPoppedState
    };
    let listFilter = `c/${Category.slugFor(category)}/${category.id}`;
    if (findOpts.no_subcategories) {
      listFilter += "/none";
    }
    listFilter += `/l/${this.filter(category)}`;
    const topicList = await findTopicList(this.store, this.topicTrackingState, listFilter, findOpts, extras);
    TopicList.hideUniformCategory(topicList, category);
    return topicList;
  }
  titleToken() {
    const category = this.currentModel.category;
    const filterText = I18n.t("filters." + this.filter(category).replace("/", ".") + ".title");
    let categoryName = category.name;
    if (category.parent_category_id) {
      const list = Category.list();
      const parentCategory = list.findBy("id", category.parent_category_id);
      categoryName = `${parentCategory.name}/${categoryName}`;
    }
    return I18n.t("filters.with_category", {
      filter: filterText,
      category: categoryName
    });
  }
  setupController(controller, model) {
    super.setupController(...arguments);
    controller.bulkSelectHelper.clear();
    this.searchService.searchContext = model.category.get("searchContext");
    setTopicList(model.list);
    const p = model.category.params;
    if (p?.order !== undefined) {
      controller.order = p.order;
    }
    if (p?.ascending !== undefined) {
      controller.ascending = p.ascending;
    }
  }
  deactivate() {
    super.deactivate(...arguments);
    this.composer.set("prioritizedCategoryId", null);
    this.searchService.searchContext = null;
  }
  setNotification(notification_level) {
    this.currentModel.setNotification(notification_level);
  }
  triggerRefresh() {
    this.refresh();
  }
  resetParams() {
    let skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    resetParams.call(this, skipParams);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "store", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "searchService", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "historyStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "setNotification", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "setNotification"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "triggerRefresh"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetParams", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetParams"), _class2.prototype)), _class2)) || _class); // A helper function to create a category route with parameters
export default function buildCategoryRoute(routeConfig) {
  return class extends AbstractCategoryRoute {
    constructor() {
      super(...arguments);
      _defineProperty(this, "routeConfig", routeConfig);
    }
  };
}