import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _SidebarAnonymousTagsSection;
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import i18n from "discourse-common/helpers/i18n";
import AllTagsSectionLink from "../common/all-tags-section-link";
import Section from "../section";
import SectionLink from "../section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
let SidebarAnonymousTagsSection = (_class = (_SidebarAnonymousTagsSection = class SidebarAnonymousTagsSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
  }
  get displaySection() {
    return this.site.anonymous_default_navigation_menu_tags?.length > 0 || this.site.navigation_menu_site_top_tags?.length > 0;
  }
  get sectionLinks() {
    return (this.site.anonymous_default_navigation_menu_tags || this.site.navigation_menu_site_top_tags).map(tag1 => {
      return new TagSectionLink({
        tag: tag1,
        topicTrackingState: this.topicTrackingState
      });
    });
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.displaySection}}
      <Section
        @sectionName="tags"
        @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
        @collapsable={{@collapsable}}
      >

        {{#each this.sectionLinks as |sectionLink|}}
          <SectionLink
            @route={{sectionLink.route}}
            @content={{sectionLink.text}}
            @title={{sectionLink.title}}
            @currentWhen={{sectionLink.currentWhen}}
            @prefixType={{sectionLink.prefixType}}
            @prefixValue={{sectionLink.prefixValue}}
            @prefixColor={{sectionLink.prefixColor}}
            @models={{sectionLink.models}}
            data-tag-name={{sectionLink.tagName}}
          />
        {{/each}}

        <AllTagsSectionLink />
      </Section>
    {{/if}}
  
*/
{
  "id": "O+dMf9tZ",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"displaySection\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@collapsable\"],[\"tags\",[28,[32,1],[\"sidebar.sections.tags.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"          \"],[8,[32,2],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@content\",\"@title\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@models\"],[[30,2,[\"route\"]],[30,2,[\"text\"]],[30,2,[\"title\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"models\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[32,3],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@collapsable\",\"sectionLink\"],false,[\"if\",\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/anonymous/tags-section.js",
  "scope": () => [Section, i18n, SectionLink, AllTagsSectionLink],
  "isStrictMode": true
}), _SidebarAnonymousTagsSection), _SidebarAnonymousTagsSection), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "sectionLinks"), _class.prototype)), _class);
export { SidebarAnonymousTagsSection as default };