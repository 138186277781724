import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { registerDestructor } from "@ember/destroyable";
import { inject as service } from "@ember/service";
import Modifier from "ember-modifier";
let DBreadcrumbsContainerModifier = (_class = class DBreadcrumbsContainerModifier extends Modifier {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "breadcrumbsService", _descriptor, this);
    _defineProperty(this, "container", null);
  }
  modify(element, _, _ref) {
    let {
      itemClass,
      linkClass
    } = _ref;
    if (this.container) {
      return;
    }
    this.container = {
      element,
      itemClass,
      linkClass
    };
    this.breadcrumbsService.registerContainer(this.container);
    registerDestructor(this, unregisterContainer);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "breadcrumbsService", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DBreadcrumbsContainerModifier as default };
function unregisterContainer(instance) {
  if (instance.container) {
    instance.breadcrumbsService.unregisterContainer(instance.container);
  }
}