import { and, eq, notEq } from "truth-helpers";
import getURL from "discourse-common/lib/get-url";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const Logo = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if (and @darkUrl (notEq @url @darkUrl))}}
    <picture>
      <source srcset={{getURL @darkUrl}} media="(prefers-color-scheme: dark)" />
      <img
        id="site-logo"
        class={{@key}}
        src={{getURL @url}}
        width={{if (eq @key "logo-small") "36"}}
        alt={{@title}}
      />
    </picture>
  {{else}}
    <img
      id="site-logo"
      class={{@key}}
      src={{getURL @url}}
      width={{if (eq @key "logo-small") "36"}}
      alt={{@title}}
    />
  {{/if}}

*/
{
  "id": "RILycffM",
  "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[28,[32,1],[[30,2],[30,1]],null]],null],[[[1,\"    \"],[10,\"picture\"],[12],[1,\"\\n      \"],[10,\"source\"],[15,\"srcset\",[28,[32,2],[[30,1]],null]],[14,\"media\",\"(prefers-color-scheme: dark)\"],[12],[13],[1,\"\\n      \"],[10,\"img\"],[14,1,\"site-logo\"],[15,0,[30,3]],[15,\"src\",[28,[32,2],[[30,2]],null]],[15,\"width\",[52,[28,[32,3],[[30,3],\"logo-small\"],null],\"36\"]],[15,\"alt\",[30,4]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"img\"],[14,1,\"site-logo\"],[15,0,[30,3]],[15,\"src\",[28,[32,2],[[30,2]],null]],[15,\"width\",[52,[28,[32,3],[[30,3],\"logo-small\"],null],\"36\"]],[15,\"alt\",[30,4]],[12],[13],[1,\"\\n\"]],[]]]],[\"@darkUrl\",\"@url\",\"@key\",\"@title\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/logo.js",
  "scope": () => [and, notEq, getURL, eq],
  "isStrictMode": true
}), templateOnly());
export default Logo;