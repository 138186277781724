import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const AddCategoryTagClasses = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}

*/
{
  "id": "LqKq7IaI",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[1,[28,[32,0],[\"category\",[28,[32,1],[\"category-\",[30,1,[\"fullSlug\"]]],null]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[1,\"    \"],[1,[28,[32,0],[[28,[32,1],[\"tag-\",[30,3]],null]],null]],[1,\"\\n\"]],[3]],null]],[\"@category\",\"@tags\",\"tag\"],false,[\"if\",\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/add-category-tag-classes.js",
  "scope": () => [bodyClass, concat],
  "isStrictMode": true
}), templateOnly());
export default AddCategoryTagClasses;