var _SecondFactorInput;
import Component from "@glimmer/component";
import { SECOND_FACTOR_METHODS } from "discourse/models/user";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class SecondFactorInput extends Component {
  get isTotp() {
    return this.args.secondFactorMethod === SECOND_FACTOR_METHODS.TOTP;
  }
  get isBackupCode() {
    return this.args.secondFactorMethod === SECOND_FACTOR_METHODS.BACKUP_CODE;
  }
  get type() {
    if (this.isTotp) {
      return "tel";
    } else if (this.isBackupCode) {
      return "text";
    }
  }
  get pattern() {
    if (this.isTotp) {
      return "[0-9]{6}";
    } else if (this.isBackupCode) {
      return "[a-z0-9]{16}";
    }
  }
  get maxlength() {
    if (this.isTotp) {
      return "6";
    } else if (this.isBackupCode) {
      return "32";
    }
  }
}
_SecondFactorInput = SecondFactorInput;
setComponentTemplate(createTemplateFactory(
/*
  
    <input
      type={{this.type}}
      pattern={{this.pattern}}
      maxlength={{this.maxlength}}
      autocomplete="one-time-code"
      autocapitalize="off"
      autocorrect="off"
      autofocus="autofocus"
      class="second-factor-token-input"
      ...attributes
    />
  
*/
{
  "id": "weUL1teh",
  "block": "[[[1,\"\\n    \"],[11,\"input\"],[16,4,[30,0,[\"type\"]]],[16,\"pattern\",[30,0,[\"pattern\"]]],[16,\"maxlength\",[30,0,[\"maxlength\"]]],[24,\"autocomplete\",\"one-time-code\"],[24,\"autocapitalize\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autofocus\",\"autofocus\"],[24,0,\"second-factor-token-input\"],[17,1],[12],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/second-factor-input.js",
  "isStrictMode": true
}), _SecondFactorInput);