import { or } from "truth-helpers";
import replaceEmoji from "discourse/helpers/replace-emoji";
import SectionLink from "./section-link";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarMoreSectionLink = setComponentTemplate(createTemplateFactory(
/*
  
  <SectionLink
    @badgeText={{@sectionLink.badgeText}}
    @content={{replaceEmoji @sectionLink.text}}
    @currentWhen={{@sectionLink.currentWhen}}
    @href={{or @sectionLink.href @sectionLink.value}}
    @linkName={{@sectionLink.name}}
    @model={{@sectionLink.model}}
    @models={{@sectionLink.models}}
    @prefixType="icon"
    @prefixValue={{@sectionLink.prefixValue}}
    @query={{@sectionLink.query}}
    @route={{@sectionLink.route}}
    @shouldDisplay={{@sectionLink.shouldDisplay}}
    @suffixCSSClass={{@sectionLink.suffixCSSClass}}
    @suffixType={{@sectionLink.suffixType}}
    @suffixValue={{@sectionLink.suffixValue}}
    @title={{@sectionLink.title}}
  />

*/
{
  "id": "Zb4Uih3O",
  "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeText\",\"@content\",\"@currentWhen\",\"@href\",\"@linkName\",\"@model\",\"@models\",\"@prefixType\",\"@prefixValue\",\"@query\",\"@route\",\"@shouldDisplay\",\"@suffixCSSClass\",\"@suffixType\",\"@suffixValue\",\"@title\"],[[30,1,[\"badgeText\"]],[28,[32,1],[[30,1,[\"text\"]]],null],[30,1,[\"currentWhen\"]],[28,[32,2],[[30,1,[\"href\"]],[30,1,[\"value\"]]],null],[30,1,[\"name\"]],[30,1,[\"model\"]],[30,1,[\"models\"]],\"icon\",[30,1,[\"prefixValue\"]],[30,1,[\"query\"]],[30,1,[\"route\"]],[30,1,[\"shouldDisplay\"]],[30,1,[\"suffixCSSClass\"]],[30,1,[\"suffixType\"]],[30,1,[\"suffixValue\"]],[30,1,[\"title\"]]]],null],[1,\"\\n\"]],[\"@sectionLink\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/more-section-link.js",
  "scope": () => [SectionLink, replaceEmoji, or],
  "isStrictMode": true
}), templateOnly());
export default SidebarMoreSectionLink;