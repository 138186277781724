import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class, _TrackSelected;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let TrackSelected = (_class = (_TrackSelected = class TrackSelected extends Component {
  onToggle(e1) {
    if (e1.target.checked) {
      this.args.selectedList.addObject(this.args.selectedId);
    } else {
      this.args.selectedList.removeObject(this.args.selectedId);
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <span class={{@class}} ...attributes>
      <input {{on "input" this.onToggle}} type="checkbox" />
    </span>
  
*/
{
  "id": "dXV51MTU",
  "block": "[[[1,\"\\n    \"],[11,1],[16,0,[30,1]],[17,2],[12],[1,\"\\n      \"],[11,\"input\"],[24,4,\"checkbox\"],[4,[32,0],[\"input\",[30,0,[\"onToggle\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@class\",\"&attrs\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/track-selected.js",
  "scope": () => [on],
  "isStrictMode": true
}), _TrackSelected), _TrackSelected), (_applyDecoratedDescriptor(_class.prototype, "onToggle", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onToggle"), _class.prototype)), _class);
export { TrackSelected as default };