import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
import { service } from "@ember/service";
import { userPath } from "discourse/lib/url";
import { postUrl } from "discourse/lib/utilities";
import { NEW_PRIVATE_MESSAGE_KEY, NEW_TOPIC_KEY } from "discourse/models/composer";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let UserDraft = (_dec = discourseComputed("draft_username"), _dec2 = discourseComputed("username_lower"), _dec3 = discourseComputed("topic_id"), _dec4 = discourseComputed("draft_key"), (_class = class UserDraft extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  editableDraft(draftUsername) {
    return draftUsername === this.currentUser?.get("username");
  }
  userUrl(usernameLower) {
    return userPath(usernameLower);
  }
  postUrl(topicId) {
    if (!topicId) {
      return;
    }
    return postUrl(this.slug, this.topic_id, this.post_number);
  }
  draftType(draftKey) {
    switch (draftKey) {
      case NEW_TOPIC_KEY:
        return I18n.t("drafts.new_topic");
      case NEW_PRIVATE_MESSAGE_KEY:
        return I18n.t("drafts.new_private_message");
      default:
        return false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "editableDraft", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "editableDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userUrl", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "userUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postUrl", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "postUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "draftType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "draftType"), _class.prototype)), _class));
export { UserDraft as default };