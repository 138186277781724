import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _TopicParticipants;
import Component from "@glimmer/component";
import TopicParticipant from "discourse/components/topic-map/topic-participant";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class TopicParticipants extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "toggledUsers", new Set(this.args.userFilters));
  }
}
_TopicParticipants = TopicParticipants;
setComponentTemplate(createTemplateFactory(
/*
  
    {{#if @title}}
      <h3>{{@title}}</h3>
    {{/if}}
    {{#each @participants as |participant|}}
      <TopicParticipant
        @participant={{participant}}
        @toggledUsers={{this.toggledUsers}}
      />
    {{/each}}
  
*/
{
  "id": "fm4yOsJg",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,\"h3\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[32,0],null,[[\"@participant\",\"@toggledUsers\"],[[30,3],[30,0,[\"toggledUsers\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[\"@title\",\"@participants\",\"participant\"],false,[\"if\",\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-map/topic-participants.js",
  "scope": () => [TopicParticipant],
  "isStrictMode": true
}), _TopicParticipants);