import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _SidebarApiSections;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import AdminHeader from "./admin-header";
import ApiSection from "./api-section";
import FilterNoResults from "./filter-no-results";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SidebarApiSections = (_class = (_SidebarApiSections = class SidebarApiSections extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  get sections() {
    if (this.sidebarState.combinedMode) {
      return this.sidebarState.panels.filter(panel1 => !panel1.hidden).flatMap(panel1 => panel1.sections);
    } else {
      return this.sidebarState.currentPanel.sections;
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <AdminHeader />

    {{#each this.sections as |sectionConfig|}}
      <ApiSection
        @sectionConfig={{sectionConfig}}
        @collapsable={{@collapsable}}
      />
    {{/each}}

    <FilterNoResults />
  
*/
{
  "id": "HX98Zixl",
  "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@sectionConfig\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n    \"],[8,[32,2],null,null,null],[1,\"\\n  \"]],[\"sectionConfig\",\"@collapsable\"],false,[\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-sections.js",
  "scope": () => [AdminHeader, ApiSection, FilterNoResults],
  "isStrictMode": true
}), _SidebarApiSections), _SidebarApiSections), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarApiSections as default };