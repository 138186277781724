import SearchMenuPanel from "../search-menu-panel";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SearchMenuWrapper = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="search-menu glimmer-search-menu" aria-live="polite">
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>

*/
{
  "id": "BpKlCF4e",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"search-menu glimmer-search-menu\"],[14,\"aria-live\",\"polite\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@closeSearchMenu\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@closeSearchMenu\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/search-menu-wrapper.js",
  "scope": () => [SearchMenuPanel],
  "isStrictMode": true
}), templateOnly());
export default SearchMenuWrapper;