import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _UserTip;
import Component from "@glimmer/component";
import { getOwner } from "@ember/application";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import UserTipContainer from "discourse/components/user-tip-container";
import escape from "discourse-common/lib/escape";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import DTooltipInstance from "float-kit/lib/d-tooltip-instance";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let UserTip = (_class = (_UserTip = class UserTip extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "userTips", _descriptor2, this);
    _initializerDefineProperty(this, "tooltip", _descriptor3, this);
    _defineProperty(this, "registerTip", modifier(() => {
      const tip1 = {
        id: this.args.id,
        priority: this.args.priority ?? 0
      };
      this.userTips.addAvailableTip(tip1);
      return () => {
        this.userTips.removeAvailableTip(tip1);
      };
    }));
    _defineProperty(this, "tip", modifier(element1 => {
      let instance1;
      schedule("afterRender", () => {
        const trigger1 = this.args.triggerSelector && document.querySelector(this.args.triggerSelector);
        let buttonText1 = escape(I18n.t(this.args.buttonLabel || "user_tips.button"));
        if (this.args.buttonIcon) {
          buttonText1 = `${iconHTML(this.args.buttonIcon)} ${buttonText1}`;
        }
        instance1 = new DTooltipInstance(getOwner(this), {
          identifier: "user-tip",
          interactive: true,
          closeOnScroll: false,
          closeOnClickOutside: true,
          placement: this.args.placement,
          component: UserTipContainer,
          data: {
            id: this.args.id,
            titleText: escape(this.args.titleText),
            contentHtml: this.args.contentHtml || null,
            contentText: this.args.contentText ? escape(this.args.contentText) : null,
            buttonText: buttonText1,
            buttonSkipText: I18n.t("user_tips.skip"),
            showSkipButton: this.args.showSkipButton
          }
        });
        instance1.trigger = trigger1 || element1;
        instance1.detachedTrigger = true;
        this.tooltip.show(instance1);
        if (this.shouldRenderTip) {
          // mark tooltip directly as seen so that
          // refreshing, clicking outside, etc. won't show it again
          this.userTips.markAsSeen(this.args.id);
        }
      });
      return () => {
        instance1?.destroy();
      };
    }));
  }
  get shouldRenderTip() {
    return this.userTips.shouldRender(this.args.id);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div {{this.registerTip}}>
      {{#if this.shouldRenderTip}}
        <span {{this.tip}}></span>
      {{/if}}
    </div>
  
*/
{
  "id": "BWjr9u13",
  "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"registerTip\"]],null,null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRenderTip\"]],[[[1,\"        \"],[11,1],[4,[30,0,[\"tip\"]],null,null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-tip.js",
  "isStrictMode": true
}), _UserTip), _UserTip), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userTips", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tooltip", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserTip as default };