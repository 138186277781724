import { fn } from "@ember/helper";
import { or } from "truth-helpers";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ShareSource = setComponentTemplate(createTemplateFactory(
/*
  
  <DButton
    @action={{fn @action @source}}
    @translatedTitle={{@source.title}}
    @icon={{or @source.icon @source.htmlIcon}}
    class="btn-default share-{{@source.id}}"
    ...attributes
  />

*/
{
  "id": "sNmo5jn3",
  "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,0,[29,[\"btn-default share-\",[30,1,[\"id\"]]]]],[17,2]],[[\"@action\",\"@translatedTitle\",\"@icon\"],[[28,[32,1],[[30,3],[30,1]],null],[30,1,[\"title\"]],[28,[32,2],[[30,1,[\"icon\"]],[30,1,[\"htmlIcon\"]]],null]]],null],[1,\"\\n\"]],[\"@source\",\"&attrs\",\"@action\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/share-source.js",
  "scope": () => [DButton, fn, or],
  "isStrictMode": true
}), templateOnly());
export default ShareSource;