import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _RenderGlimmerContainer;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let RenderGlimmerContainer = (_class = (_RenderGlimmerContainer = class RenderGlimmerContainer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "renderGlimmer", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#each this.renderGlimmer._registrations as |info|}}
      {{#in-element info.element insertBefore=null}}
        <info.component
          @data={{info.data}}
          @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
        />
      {{/in-element}}
    {{/each}}
  
*/
{
  "id": "NknMf/qr",
  "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"renderGlimmer\",\"_registrations\"]]],null]],null],null,[[[40,[[[1,\"        \"],[8,[30,1,[\"component\"]],null,[[\"@data\",\"@setWrapperElementAttrs\"],[[30,1,[\"data\"]],[30,1,[\"setWrapperElementAttrs\"]]]],null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[31,3],[[30,1,[\"element\"]]],null],null]],[1]],null],[1,\"  \"]],[\"info\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/render-glimmer-container.js",
  "isStrictMode": true
}), _RenderGlimmerContainer), _RenderGlimmerContainer), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "renderGlimmer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { RenderGlimmerContainer as default };