import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _SwitchPanelButtons;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { fn } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let SwitchPanelButtons = (_class = (_SwitchPanelButtons = class SwitchPanelButtons extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor2, this);
    _initializerDefineProperty(this, "isSwitching", _descriptor3, this);
  }
  async switchPanel(panel1) {
    this.isSwitching = true;
    this.sidebarState.currentPanel.lastKnownURL = this.router.currentURL;
    const destination1 = panel1?.switchButtonDefaultUrl || panel1?.lastKnownURL;
    if (!destination1) {
      return;
    }
    try {
      await this.router.transitionTo(destination1).followRedirects();
      this.sidebarState.setPanel(panel1.key);
    } catch (e1) {
      if (e1.name !== "TransitionAborted") {
        throw e1;
      }
    } finally {
      this.isSwitching = false;
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#each @buttons as |button|}}
      <DButton
        @action={{fn this.switchPanel button}}
        @icon={{button.switchButtonIcon}}
        @disabled={{this.isSwitching}}
        @translatedLabel={{button.switchButtonLabel}}
        data-key={{button.key}}
        class="btn-default sidebar__panel-switch-button"
      />
    {{/each}}
  
*/
{
  "id": "pA0yonLl",
  "block": "[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[32,0],[[16,\"data-key\",[30,2,[\"key\"]]],[24,0,\"btn-default sidebar__panel-switch-button\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[28,[32,1],[[30,0,[\"switchPanel\"]],[30,2]],null],[30,2,[\"switchButtonIcon\"]],[30,0,[\"isSwitching\"]],[30,2,[\"switchButtonLabel\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[\"@buttons\",\"button\"],false,[\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/switch-panel-buttons.js",
  "scope": () => [DButton, fn],
  "isStrictMode": true
}), _SwitchPanelButtons), _SwitchPanelButtons), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSwitching", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "switchPanel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "switchPanel"), _class.prototype)), _class);
export { SwitchPanelButtons as default };