import { hash } from "@ember/helper";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import PluginOutlet from "./plugin-outlet";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ConditionalLoadingSpinner = setComponentTemplate(createTemplateFactory(
/*
  
  <PluginOutlet
    @name="conditional-loading-spinner"
    @defaultGlimmer={{true}}
    @outletArgs={{hash condition=@condition size=@size}}
  >
    <div
      class={{concatClass
        "loading-container"
        (if @condition "visible")
        (if (eq @size "small") "inline-spinner")
      }}
    >
      {{#if @condition}}
        <div class="spinner {{@size}}"></div>
      {{else}}
        {{yield}}
      {{/if}}
    </div>
  </PluginOutlet>

*/
{
  "id": "2tOpFTGI",
  "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@name\",\"@defaultGlimmer\",\"@outletArgs\"],[\"conditional-loading-spinner\",true,[28,[32,1],null,[[\"condition\",\"size\"],[[30,1],[30,2]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,2],[\"loading-container\",[52,[30,1],\"visible\"],[52,[28,[32,3],[[30,2],\"small\"],null],\"inline-spinner\"]],null]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[15,0,[29,[\"spinner \",[30,2]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,3,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@condition\",\"@size\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/conditional-loading-spinner.js",
  "scope": () => [PluginOutlet, hash, concatClass, eq],
  "isStrictMode": true
}), templateOnly());
export default ConditionalLoadingSpinner;