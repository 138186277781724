import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import DismissNotificationConfirmationModal from "discourse/components/modal/dismiss-notification-confirmation";
import RelativeDate from "discourse/components/relative-date";
import { ajax } from "discourse/lib/ajax";
import UserMenuNotificationItem from "discourse/lib/user-menu/notification-item";
import getURL from "discourse-common/lib/get-url";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const _beforeLoadMoreCallbacks = [];
export function addBeforeLoadMoreCallback(fn1) {
  _beforeLoadMoreCallbacks.push(fn1);
}
let UserNotificationsController = (_dec = discourseComputed("filter"), _dec2 = discourseComputed("model.content.@each"), _dec3 = discourseComputed("model.content.@each.read"), _dec4 = discourseComputed("isFiltered", "model.content.length", "loading"), _dec5 = discourseComputed("isFiltered", "model.content.length"), _dec6 = discourseComputed(), (_class = class UserNotificationsController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
    _initializerDefineProperty(this, "appEvents", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _initializerDefineProperty(this, "site", _descriptor4, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor5, this);
    _initializerDefineProperty(this, "filter", _descriptor6, this);
    _defineProperty(this, "queryParams", ["filter"]);
  }
  get listContainerClassNames() {
    return `user-notifications-list ${this.siteSettings.show_user_menu_avatars ? "show-avatars" : ""}`;
  }
  isFiltered() {
    return this.filter && this.filter !== "all";
  }
  items() {
    return this.model.map(notification => {
      const props = {
        appEvents: this.appEvents,
        currentUser: this.currentUser,
        siteSettings: this.siteSettings,
        site: this.site,
        notification,
        endComponent: setComponentTemplate(createTemplateFactory(
        /*
          
                  <RelativeDate @date={{notification.created_at}} />
                
        */
        {
          "id": "Nwrbyhur",
          "block": "[[[1,\"\\n          \"],[8,[32,0],null,[[\"@date\"],[[32,1,[\"created_at\"]]]],null],[1,\"\\n        \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/controllers/user-notifications.js",
          "scope": () => [RelativeDate, notification],
          "isStrictMode": true
        }), templateOnly())
      };
      return new UserMenuNotificationItem(props);
    });
  }
  allNotificationsRead() {
    return !this.get("model.content").some(notification1 => !notification1.get("read"));
  }
  doesNotHaveNotifications(isFiltered1, contentLength1, loading1) {
    return !loading1 && !isFiltered1 && contentLength1 === 0;
  }
  nothingFound(isFiltered1, contentLength1) {
    return isFiltered1 && contentLength1 === 0;
  }
  emptyStateBody() {
    return htmlSafe(I18n.t("user.no_notifications_page_body", {
      preferencesUrl: getURL("/my/preferences/notifications"),
      icon: iconHTML("bell")
    }));
  }
  async markRead() {
    await ajax("/notifications/mark-read", {
      type: "PUT"
    });
    this.model.forEach(notification1 => notification1.set("read", true));
  }
  updateFilter(value1) {
    this.loading = true;
    this.filter = value1;
  }
  async resetNew() {
    if (this.currentUser.unread_high_priority_notifications > 0) {
      this.modal.show(DismissNotificationConfirmationModal, {
        model: {
          confirmationMessage: I18n.t("notifications.dismiss_confirmation.body.default", {
            count: this.currentUser.unread_high_priority_notifications
          }),
          dismissNotifications: () => this.markRead()
        }
      });
    } else {
      this.markRead();
    }
  }
  loadMore() {
    if (_beforeLoadMoreCallbacks.length && !_beforeLoadMoreCallbacks.some(fn1 => fn1(this))) {
      // Return early if any callbacks return false, short-circuiting the default loading more logic
      return;
    }
    this.model.loadMore();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filter", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "all";
  }
}), _applyDecoratedDescriptor(_class.prototype, "isFiltered", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isFiltered"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allNotificationsRead", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "allNotificationsRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doesNotHaveNotifications", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "doesNotHaveNotifications"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nothingFound", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "nothingFound"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emptyStateBody", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "emptyStateBody"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetNew", [action], Object.getOwnPropertyDescriptor(_class.prototype, "resetNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype)), _class));
export { UserNotificationsController as default };