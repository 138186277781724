import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _ReviewableBundledAction;
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { dasherize } from "@ember/string";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import { isRTL } from "discourse/lib/text-direction";
import DropdownSelectBox from "select-kit/components/dropdown-select-box";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let ReviewableBundledAction = (_class = (_ReviewableBundledAction = class ReviewableBundledAction extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
  }
  get multiple() {
    return this.args.bundle.actions.length > 1;
  }
  get first() {
    return this.args.bundle.actions[0];
  }
  get placement() {
    const vertical1 = this.site.mobileView ? "top" : "bottom";
    const horizontal1 = isRTL() ? "end" : "start";
    return `${vertical1}-${horizontal1}`;
  }
  perform(id1) {
    if (id1) {
      const _action1 = this.args.bundle.actions.find(a1 => a1.id === id1);
      this.args.performAction(_action1);
    } else {
      this.args.performAction(this.first);
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.multiple}}
      <DropdownSelectBox
        @nameProperty="label"
        @content={{@bundle.actions}}
        @onChange={{this.perform}}
        @options={{hash
          showCaret=true
          disabled=@reviewableUpdating
          placement=this.placement
          translatedNone=@bundle.label
        }}
        class={{concatClass
          "reviewable-action-dropdown"
          "btn-icon-text"
          (dasherize this.first.id)
          this.first.button_class
        }}
      />
    {{else}}
      <DButton
        @action={{this.perform}}
        @translatedLabel={{this.first.label}}
        @disabled={{@reviewableUpdating}}
        class={{concatClass
          "btn-default reviewable-action"
          (dasherize this.first.id)
          this.first.button_class
        }}
      />
    {{/if}}
  
*/
{
  "id": "9U3p/RCO",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"multiple\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"reviewable-action-dropdown\",\"btn-icon-text\",[28,[32,2],[[30,0,[\"first\",\"id\"]]],null],[30,0,[\"first\",\"button_class\"]]],null]]],[[\"@nameProperty\",\"@content\",\"@onChange\",\"@options\"],[\"label\",[30,1,[\"actions\"]],[30,0,[\"perform\"]],[28,[32,3],null,[[\"showCaret\",\"disabled\",\"placement\",\"translatedNone\"],[true,[30,2],[30,0,[\"placement\"]],[30,1,[\"label\"]]]]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,4],[[16,0,[28,[32,1],[\"btn-default reviewable-action\",[28,[32,2],[[30,0,[\"first\",\"id\"]]],null],[30,0,[\"first\",\"button_class\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@disabled\"],[[30,0,[\"perform\"]],[30,0,[\"first\",\"label\"]],[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@bundle\",\"@reviewableUpdating\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/reviewable-bundled-action.js",
  "scope": () => [DropdownSelectBox, concatClass, dasherize, hash, DButton],
  "isStrictMode": true
}), _ReviewableBundledAction), _ReviewableBundledAction), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "perform", [action], Object.getOwnPropertyDescriptor(_class.prototype, "perform"), _class.prototype)), _class);
export { ReviewableBundledAction as default };