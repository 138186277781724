import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _FastEdit;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import DButton from "discourse/components/d-button";
import PluginOutlet from "discourse/components/plugin-outlet";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { translateModKey } from "discourse/lib/utilities";
import autoFocus from "discourse/modifiers/auto-focus";
import I18n from "discourse-i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let FastEdit = (_class = (_FastEdit = class FastEdit extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "isSaving", _descriptor, this);
    _initializerDefineProperty(this, "value", _descriptor2, this);
    _defineProperty(this, "buttonTitle", I18n.t("composer.title", {
      modifier: translateModKey("Meta+")
    }));
  }
  get disabled() {
    return this.value === this.args.initialValue;
  }
  onKeydown(event1) {
    if (event1.key === "Enter" && (event1.ctrlKey || event1.metaKey) && !this.disabled) {
      this.save();
    }
  }
  updateValue(event1) {
    event1.preventDefault();
    this.value = event1.target.value;
  }
  updateValueProperty(value1) {
    this.value = value1;
  }
  async save() {
    this.isSaving = true;
    try {
      const result1 = await ajax(`/posts/${this.args.post.id}`);
      const newRaw1 = result1.raw.replace(this.args.initialValue, this.value);
      await this.args.post.save({
        raw: newRaw1
      });
    } catch (error1) {
      popupAjaxError(error1);
    } finally {
      this.isSaving = false;
      this.args.close();
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{! template-lint-disable no-pointer-down-event-binding }}
    {{! template-lint-disable no-invalid-interactive }}
    <div class="fast-edit-container" {{on "keydown" this.onKeydown}}>
      <textarea
        {{on "input" this.updateValue}}
        id="fast-edit-input"
        {{autoFocus}}
      >{{this.value}}</textarea>

      <div class="fast-edit-container__footer">
        <DButton
          class="btn-small btn-primary save-fast-edit"
          @action={{this.save}}
          @icon="pencil-alt"
          @label="composer.save_edit"
          @translatedTitle={{this.buttonTitle}}
          @isLoading={{this.isSaving}}
          @disabled={{this.disabled}}
        />

        <PluginOutlet
          @name="fast-edit-footer-after"
          @defaultGlimmer={{true}}
          @outletArgs={{hash
            initialValue=@initialValue
            newValue=@newValue
            updateValue=this.updateValueProperty
          }}
        />
      </div>
    </div>
  
*/
{
  "id": "VCTvdRnx",
  "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,0,\"fast-edit-container\"],[4,[32,0],[\"keydown\",[30,0,[\"onKeydown\"]]],null],[12],[1,\"\\n      \"],[11,\"textarea\"],[24,1,\"fast-edit-input\"],[4,[32,0],[\"input\",[30,0,[\"updateValue\"]]],null],[4,[32,1],null,null],[12],[1,[30,0,[\"value\"]]],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"fast-edit-container__footer\"],[12],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-small btn-primary save-fast-edit\"]],[[\"@action\",\"@icon\",\"@label\",\"@translatedTitle\",\"@isLoading\",\"@disabled\"],[[30,0,[\"save\"]],\"pencil-alt\",\"composer.save_edit\",[30,0,[\"buttonTitle\"]],[30,0,[\"isSaving\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@name\",\"@defaultGlimmer\",\"@outletArgs\"],[\"fast-edit-footer-after\",true,[28,[32,4],null,[[\"initialValue\",\"newValue\",\"updateValue\"],[[30,1],[30,2],[30,0,[\"updateValueProperty\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@initialValue\",\"@newValue\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/fast-edit.js",
  "scope": () => [on, autoFocus, DButton, PluginOutlet, hash],
  "isStrictMode": true
}), _FastEdit), _FastEdit), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSaving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.newValue || this.args.initialValue;
  }
}), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValue", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateValueProperty", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateValueProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
export { FastEdit as default };