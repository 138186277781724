import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _ToggleAllSections;
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ADMIN_NAV_MAP } from "discourse/lib/sidebar/admin-nav-map";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let ToggleAllSections = (_class = (_ToggleAllSections = class ToggleAllSections extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
    _initializerDefineProperty(this, "keyValueStore", _descriptor2, this);
  }
  get allSectionsExpanded() {
    return ADMIN_NAV_MAP.every(adminNav1 => {
      return !this.sidebarState.collapsedSections.has(`sidebar-section-${this.sidebarState.currentPanel.key}-${adminNav1.name}-collapsed`);
    });
  }
  get title() {
    return this.allSectionsExpanded ? "admin.collapse_all_sections" : "admin.expand_all_sections";
  }
  get icon() {
    return this.allSectionsExpanded ? "discourse-chevron-collapse" : "discourse-chevron-expand";
  }
  toggleAllSections() {
    const collapse1 = this.allSectionsExpanded;
    ADMIN_NAV_MAP.forEach(adminNav1 => {
      const key1 = `${this.sidebarState.currentPanel.key}-${adminNav1.name}`;
      if (collapse1) {
        this.sidebarState.collapseSection(key1);
      } else {
        this.sidebarState.expandSection(key1);
      }
    });
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DButton
      @action={{this.toggleAllSections}}
      @icon={{this.icon}}
      @title={{this.title}}
      class="btn-transparent sidebar-toggle-all-sections"
    />
  
*/
{
  "id": "PgR1VP+Z",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-transparent sidebar-toggle-all-sections\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"toggleAllSections\"]],[30,0,[\"icon\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/toggle-all-sections.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _ToggleAllSections), _ToggleAllSections), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "keyValueStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "toggleAllSections", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAllSections"), _class.prototype)), _class);
export { ToggleAllSections as default };