import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const SidebarSectionHeader = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @collapsable}}
    <DButton
      @title="sidebar.toggle_section"
      @action={{@toggleSectionDisplay}}
      aria-controls={{@sidebarSectionContentID}}
      aria-expanded={{if @isExpanded "true" "false"}}
      class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
    >
      {{yield}}
    </DButton>
  {{else}}
    <span class="sidebar-section-header">
      {{yield}}
    </span>
  {{/if}}

*/
{
  "id": "0zBu0Bne",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[32,0],[[16,\"aria-controls\",[30,2]],[16,\"aria-expanded\",[52,[30,3],\"true\",\"false\"]],[24,0,\"sidebar-section-header sidebar-section-header-collapsable btn-transparent\"]],[[\"@title\",\"@action\"],[\"sidebar.toggle_section\",[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"sidebar-section-header\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[\"@collapsable\",\"@sidebarSectionContentID\",\"@isExpanded\",\"@toggleSectionDisplay\",\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/section-header.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), templateOnly());
export default SidebarSectionHeader;