import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const InputTip = setComponentTemplate(createTemplateFactory(
/*
  
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "times" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>

*/
{
  "id": "73MOEKwd",
  "block": "[[[1,\"\\n  \"],[11,0],[16,0,[29,[\"tip \",[52,[30,1,[\"failed\"]],\"bad\",\"good\"]]]],[16,1,[30,2]],[17,3],[12],[1,\"\\n\"],[41,[30,1,[\"reason\"]],[[[1,\"      \"],[1,[28,[32,0],[[52,[30,1,[\"failed\"]],\"times\",\"check\"]],null]],[1,\"\\n      \"],[1,[30,1,[\"reason\"]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@validation\",\"@id\",\"&attrs\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/input-tip.js",
  "scope": () => [icon],
  "isStrictMode": true
}), templateOnly());
export default InputTip;