import DButton from "discourse/components/d-button";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const PasskeyLoginButton = setComponentTemplate(createTemplateFactory(
/*
  
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />

*/
{
  "id": "8Pqh+8ta",
  "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn-social passkey-login-button\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,1],\"user\",\"login.passkey.name\"]],null],[1,\"\\n\"]],[\"@passkeyLogin\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/passkey-login-button.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), templateOnly());
export default PasskeyLoginButton;